<template>
  <div v-click-outside="handleOutsideClick" class="inline-block leading-none">
    <button
      v-if="isUsingCustomTrigger"
      ref="button"
      type="button"
      :class="{ 'cursor-not-allowed opacity-50': shouldDisableTrigger }"
      :aria-label="triggerAriaLabel ? triggerAriaLabel : null"
      :disabled="shouldDisableTrigger"
      @blur="$emit('blur')"
      @click.prevent="toggleOpen"
      @keydown.down.stop.prevent
      @keydown.enter.stop.prevent
      @keydown.space.stop.prevent
      @keyup.down.stop.prevent="handleTriggerDown"
      @keyup.enter.stop.prevent="handleTriggerEnter"
      @keyup.esc.stop.prevent="handleLinkEsc"
      @keyup.space.stop.prevent="handleTriggerSpace"
    >
      <slot name="trigger" v-bind="{ open }" />
    </button>
    <cx-button
      v-else
      ref="button"
      v-bind="buttonProps"
      type="button"
      :disabled="shouldDisableTrigger"
      @blur="$emit('blur')"
      @click.prevent="toggleOpen"
      @keydown.down.stop.prevent
      @keydown.enter.stop.prevent
      @keydown.space.stop.prevent
      @keyup.down.stop.prevent="handleTriggerDown"
      @keyup.esc.stop.prevent="handleLinkEsc"
      @keyup.enter.stop.prevent="handleTriggerEnter"
      @keyup.space.stop.prevent="handleTriggerSpace"
    >
      <template v-if="$slots.buttonLabel || buttonLabel">
        <slot name="buttonLabel">{{ buttonLabel }}</slot>
      </template>
      <cx-spinner v-if="loading" :color="spinnerColor" class="ml-[21px]" size="xs" />
    </cx-button>
    <div
      v-show="open && !loading"
      ref="menu"
      class="bg-white inline-block list-none min-w-48 rounded shadow text-base z-50"
      @keydown="handleKeydown"
      @keyup="handleKeyup"
    >
      <div v-if="!!$slots.header" class="border-b border-gray-200 pb-2 px-4">
        <slot name="header" />
      </div>
      <div v-if="menuLabel" class="font-semibold pb-1 pt-4 px-4 text-sm">{{ menuLabel }}</div>
      <div v-if="menuSublabel" class="border-b border-gray-100 font-normal pb-2 px-4 text-gray-500 text-xs">
        {{ menuSublabel }}
      </div>
      <div v-if="!$slots.content" class="py-1" @click.stop="handleOptionSelect">
        <cx-dropdown-links ref="links">
          <links />
        </cx-dropdown-links>
      </div>
      <slot name="content" />
      <div v-if="!!$slots.footer" class="border-gray-200 border-t flex flex-none items-center">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import vClickOutside from 'click-outside-vue3';
import { reject } from 'lodash';

import {
  DANGER,
  DANGER_LIGHT,
  PRIMARY,
  QUATERNARY,
  SECONDARY,
  TERTIARY,
  WARN,
  WARN_OUTLINE,
} from '~/components/cx/CxButton/CxButton.vue';
import { EXPAND_LESS_ICON, EXPAND_MORE_ICON, KEYCODES, KEYCODE_MAP } from '~/support/constants';

import CxDropdownLinks from './subcomponents/CxDropdownLinks.vue';
import { CxButton } from '../CxButton';
import { CxSpinner } from '../CxSpinner';
import { WHITE } from '../CxSpinner/CxSpinner.vue';

export { default as CxDropdownDivider } from './subcomponents/CxDropdownDivider.vue';
export { default as CxDropdownLink } from './subcomponents/CxDropdownLink.vue';

export const SPINNER_COLOR_MAP = {
  [DANGER]: DANGER,
  [DANGER_LIGHT]: WHITE,
  [PRIMARY]: PRIMARY,
  [SECONDARY]: SECONDARY,
  [TERTIARY]: TERTIARY,
  [QUATERNARY]: QUATERNARY,
  [WARN_OUTLINE]: WARN_OUTLINE,
  [WARN]: WARN,
};
export const CX_DROPDOWN_KEYCODES = [
  KEYCODES.DOWN,
  KEYCODES.ENTER,
  KEYCODES.ESC,
  KEYCODES.SPACE,
  KEYCODES.TAB,
  KEYCODES.UP,
];

export default {
  name: 'CxDropdown',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    links: {
      render({ $parent }) {
        let order = 0;

        return ($parent.$parent.$slots.default?.() || [])
          .filter(({ type }) => !!type.name)
          .map((link) => {
            if (link.type.name === 'CxDropdownDivider') return link;

            const linkOrder = link.props.disabled === true || link.props.disabled === '' ? null : ++order;
            link.props.order = linkOrder;

            return link;
          });
      },
    },

    CxButton,
    CxDropdownLinks,
    CxSpinner,
  },

  provide() {
    return {
      highlighted: this.highlighted,
    };
  },

  props: {
    autohide: {
      default: true,
      type: Boolean,
    },

    buttonLabel: {
      default: '',
      type: String,
    },

    color: {
      default: PRIMARY,
      type: String,
    },

    disableTrigger: {
      default: false,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    hideIcon: {
      default: false,
      type: Boolean,
    },

    horizontalOffset: {
      default: 0,
      type: Number,
    },

    /* eslint-disable vue/require-default-prop */
    icon: String,

    isOpen: {
      default: null,
      type: Boolean,
    },

    keepOpen: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    menuLabel: {
      default: '',
      type: String,
    },

    menuSublabel: {
      default: '',
      type: String,
    },

    triggerAriaLabel: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      highlighted: { item: 0 },
      open: false,
    };
  },

  computed: {
    buttonIcon() {
      return this.open ? EXPAND_LESS_ICON : EXPAND_MORE_ICON;
    },

    buttonProps() {
      const { loading, ...attrs } = this.$props;
      const showIcon = !this.hideIcon && !loading;

      return {
        ...attrs,
        ...(showIcon && { icon: this.icon || this.buttonIcon }),
        trailing: true,
      };
    },

    defaultSlotChildren() {
      return this.$slots.default?.() || [];
    },

    dropdownLinks() {
      return this.defaultSlotChildren.filter(({ type }) => type.name === 'CxDropdownLink');
    },

    enabledDropdownLinks() {
      return reject(this.dropdownLinks, (link) => link.props.disabled === true || link.props.disabled === '');
    },

    hasEnabledLinks() {
      return this.totalLinks > 0;
    },

    highlightedItemIsFirst() {
      return this.highlighted.item === 1;
    },

    highlightedItemIsLast() {
      return this.highlighted.item === this.totalLinks;
    },

    isUsingCustomTrigger() {
      return !!this.$slots.trigger;
    },

    shouldDisableTrigger() {
      if (this.$slots.content) return false;

      const children = this.defaultSlotChildren.filter(({ type }) => !!type.name);

      return this.disableTrigger || !children.length;
    },

    spinnerColor() {
      return SPINNER_COLOR_MAP[this.color];
    },

    totalLinks() {
      return this.enabledDropdownLinks.length;
    },
  },

  watch: {
    '$route.name'(newVal) {
      if (!newVal) return;

      this.resetHighlightedItem();
    },

    isOpen(newVal) {
      this.open = this.isOpen;
    },

    open(newVal) {
      this.$emit('toggled', newVal);
      if (!newVal) return this.resetHighlightedItem();

      this.$nextTick(() => {
        this.createPopper();
      });
    },
  },

  methods: {
    closeMenu() {
      if (this.shouldDisableTrigger) return;

      this.open = false;
      this.resetHighlightedItem();
    },

    createPopper() {
      this.popper = createPopper(this.$el, this.$refs.menu, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.horizontalOffset, 8],
            },
          },
        ],
      });

      this.popper.forceUpdate();
    },

    focusButton() {
      if (!this.isUsingCustomTrigger) return this.$refs.button.$el.focus();

      this.$refs.button.focus();
    },

    handleKeydown(event) {
      if (event.shiftKey && event.keyCode === KEYCODES.TAB) {
        event.stopPropagation();
        this.handleLinkUp();
      } else if (event.keyCode === KEYCODES.TAB) {
        event.stopPropagation();
        this.handleLinkDown();
      } else if (event.keyCode === KEYCODES.ENTER) {
        event.stopPropagation();
        if (!this.keepOpen) this.resetHighlightedItem();
      }
    },

    handleKeyup(event) {
      if (!CX_DROPDOWN_KEYCODES.includes(event.keyCode)) return;

      event.stopPropagation();
      event.preventDefault();

      if (event.keyCode === KEYCODES.DOWN || event.keyCode === KEYCODES.ESC || event.keyCode === KEYCODES.UP) {
        this[`handleLink${KEYCODE_MAP[event.keyCode.toString()]}`]();
      }
    },

    handleLinkDown() {
      if (!this.highlightedItemIsLast) return this.highlighted.item++;
    },

    handleLinkEsc() {
      if (!this.open) return;

      this.closeMenu();
      this.$nextTick(() => {
        this.focusButton();
      });
    },

    handleLinkSpace() {
      this.closeMenu();
      this.focusButton();
    },

    handleLinkUp() {
      if (this.highlightedItemIsFirst) {
        this.closeMenu();
        this.$nextTick(() => {
          this.focusButton();
        });
      } else {
        this.highlighted.item--;
      }
    },

    handleOptionSelect() {
      if (!this.keepOpen && !this.isOpen) this.closeMenu();
    },

    handleOutsideClick() {
      if (this.autohide) this.closeMenu();
    },

    handleTriggerDown() {
      this.openMenu();
      this.setHighlightedToFirst();
    },

    handleTriggerEnter() {
      if (this.open && !this.hasEnabledLinks) return this.closeMenu();

      this.openMenu();
      this.setHighlightedToFirst();
    },

    handleTriggerSpace() {
      this.toggleOpen();
      this.setHighlightedToFirst();
      !this.open && this.focusButton();
    },

    openMenu() {
      this.open = true;
    },

    resetHighlightedItem() {
      this.highlighted.item = 0;
    },

    setHighlightedToFirst() {
      this.hasEnabledLinks && (this.highlighted.item = 1);
    },

    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>
