import ApiBase from './ApiBase';

export const ACTIVATE_ENDPOINT = 'activate';
export const CAN_ACTIVATE_ENDPOINT = 'can-activate';
export const CANCEL_PROFILING_ENDPOINT = 'cancel-profiling';
export const DATA_PRODUCT_CONNECTION_ENDPOINT = '/connection';
export const DATA_PRODUCT_DEPLOYMENT_CHECKS_ENDPOINT = '/deployment-checks';
export const DATA_PRODUCT_DESTINATIONS_ENDPOINT = 'destinations';
export const DATA_PRODUCT_DESTINATION_META_ENDPOINT = 'destinations/meta';
export const DATA_PRODUCT_HISTORY_ENDPOINT = 'history';
export const DATA_PRODUCT_STATUS_ENDPOINT = '/status';
export const DATA_PRODUCTS_ENDPOINT = '/';
export const DATA_PRODUCTS_IMPORT_ENDPOINT = '/import';
export const DATA_PRODUCTS_BULK_CREATE_ENDPOINT = '/bulk';
export const DATASETS_ENDPOINT = 'datasets';
export const DEACTIVATE_ENDPOINT = 'deactivate';
export const ENUMS_ENDPOINT = 'enums';
export const EXCLUDE_FRAMES_ENDPOINT = 'exclude-frames';
export const FILE_PATTERNS_ENDPOINT = 'file-patterns';
export const FRAMES_ENDPOINT = 'frames';
export const INFERRED_DATASETS_ENDPOINT = 'inferred-datasets';
export const INTEGRATION_ENTITIES_ENDPOINT = 'integration-entities';
export const PAUSE_ENDPOINT = 'pause';
export const PRODUCT_FAMILIES = 'families';
export const PROFILE_ENDPOINT = 'profile';
export const PROFILE_JOB_ENDPOINT = 'profile-job';
export const RESUME_ENDPOINT = 'resume';
export const REDEPLOY_ENDPOINT = 'redeploy';
export const REPROFILE_ENDPOINT = 'reprofile';
export const SKIP_PROFILING_ENDPOINT = 'skip-profiling';
export const TEST_ENDPOINT = 'test';
export const WORKFLOWS_ENDPOINT = 'workflows';

export default class DataProductsService extends ApiBase {
  activateDataProduct(dataProductId) {
    return this.client.put(`${dataProductId}/${ACTIVATE_ENDPOINT}`);
  }

  async bulkCreate(payload) {
    const { data } = await this.client.post(`${DATA_PRODUCTS_BULK_CREATE_ENDPOINT}`, payload);

    return data;
  }

  async pauseDataProduct(dataProductId) {
    return await this.client.put(`${dataProductId}/${PAUSE_ENDPOINT}`);
  }

  async resumeDataProduct(dataProductId) {
    return await this.client.put(`${dataProductId}/${RESUME_ENDPOINT}`);
  }

  async activateProfilingNotification({ dataProductId }) {
    return await this.client.post(`${dataProductId}/${PROFILE_JOB_ENDPOINT}`);
  }

  canActivateDataProduct() {
    return this.client.get(`/${CAN_ACTIVATE_ENDPOINT}`);
  }

  cancelProfiling(dataProductId) {
    return this.client.post(`${dataProductId}/${CANCEL_PROFILING_ENDPOINT}`);
  }

  async cancelProfilingNotification({ dataProductId }) {
    return await this.client.delete(`${dataProductId}/${PROFILE_JOB_ENDPOINT}`);
  }

  async createDataProduct(payload) {
    const { data } = await this.client.post(``, payload);

    return data;
  }

  async importDataProduct(payload) {
    const { data } = await this.client.post(DATA_PRODUCTS_IMPORT_ENDPOINT, payload);

    return data;
  }

  async createInferredDatasets(dataProductId, patterns) {
    const { data } = await this.client.post(`/${dataProductId}/${INFERRED_DATASETS_ENDPOINT}`, patterns);

    return data;
  }

  async createWorkflows(payload) {
    const { data } = await this.client.post(`/${payload.id}/${WORKFLOWS_ENDPOINT}`, payload.datastoreIds);

    return data;
  }

  deactivateDataProduct(dataProductId) {
    return this.client.post(`${dataProductId}/${DEACTIVATE_ENDPOINT}`);
  }

  async deleteDataProductSubscription({ dataProductId, subscriptionId }) {
    const { data } = await this.client.delete(`${dataProductId}/subscriptions/${subscriptionId}`);

    return data;
  }

  async deleteIntegrationEntities(dataProductId) {
    const { data } = await this.client.delete(`/${dataProductId}/${INTEGRATION_ENTITIES_ENDPOINT}`);

    return data;
  }

  excludeFrames({ dataProductId, selectedIds }) {
    return this.client.put(`${dataProductId}/${EXCLUDE_FRAMES_ENDPOINT}`, { selectedIds });
  }

  async fetchAllConfigs(id) {
    const { data } = await this.client.get(`/${id}/download/configs`);

    return data;
  }

  async fetchConnectionByProductId(id) {
    const { data } = await this.client.get(`/${id}${DATA_PRODUCT_CONNECTION_ENDPOINT}`);

    return data;
  }

  async fetchDataProductSubscribers(params) {
    const { data } = await this.client.get(`${params.dataProductId}/subscribers`, { params });

    return data;
  }

  async fetchDataProductSubscription(params) {
    const { data } = await this.client.get(`${params.dataProductId}/subscriptions`, { params });

    return data;
  }

  async fetchDataProductFamiliesByOrg(legacyId) {
    const { data } = await this.client.get(`/${PRODUCT_FAMILIES}?legacyId=${legacyId}`);

    return data;
  }

  async fetchDatasets(dataProductId) {
    const { data } = await this.client.get(`/${dataProductId}/${DATASETS_ENDPOINT}`);

    return data;
  }

  async fetchDataProduct(id) {
    const { data } = await this.client.get(`/${id}`);

    return data;
  }

  async fetchDataProductDestinationMeta(id) {
    const { data } = await this.client.get(`${id}/${DATA_PRODUCT_DESTINATION_META_ENDPOINT}`);

    return data;
  }

  async fetchDataProductDestinations(id) {
    const { data } = await this.client.get(`${id}/${DATA_PRODUCT_DESTINATIONS_ENDPOINT}`);

    return data;
  }

  async fetchDataProductHistory(id) {
    const { data } = await this.client.get(`${id}/${DATA_PRODUCT_HISTORY_ENDPOINT}`);

    return data;
  }

  async fetchDataProductStatus(id) {
    const data = await this.client.get(`${id}${DATA_PRODUCT_STATUS_ENDPOINT}`);

    return data;
  }

  async fetchDataProductDeploymentChecks(id) {
    const data = await this.client.get(`${id}${DATA_PRODUCT_DEPLOYMENT_CHECKS_ENDPOINT}`);

    return data;
  }

  async fetchDataProducts(params) {
    const { data } = await this.client.get(``, { params });

    return data;
  }

  async fetchEnums(name) {
    const { data } = await this.client.get(`/${ENUMS_ENDPOINT}/${name}`);

    return data;
  }

  async fetchEnumsList() {
    const data = await this.client.get(`/${ENUMS_ENDPOINT}`);

    return data;
  }

  async fetchFilePatterns(dataProductId) {
    const { data } = await this.client.get(`/${dataProductId}/${FILE_PATTERNS_ENDPOINT}`);

    return data;
  }

  patchDataProduct(dataProductId, payload) {
    return this.client.patch(`/${dataProductId}`, payload);
  }

  profile({ dataProductId, frameIds, lastDeliveryOnly }) {
    return this.client.post(`/${dataProductId}/${PROFILE_ENDPOINT}`, {
      frameIds,
      lastDeliveryOnly,
    });
  }

  reprofile({ asRaw = false, dataProductId, frameIds }) {
    return this.client.post(`/${dataProductId}/${REPROFILE_ENDPOINT}`, {
      asRaw,
      frameIds,
    });
  }

  redeployDataProduct(dataProductId) {
    return this.client.put(`${dataProductId}/${REDEPLOY_ENDPOINT}`);
  }

  skipProfiling({ dataProductId, frameIds }) {
    return this.client.post(`${dataProductId}/${SKIP_PROFILING_ENDPOINT}`, {
      frameIds,
    });
  }

  testDataProduct(payload) {
    const { dataProductId, testWithLatestData } = payload;

    return this.client.put(`${dataProductId}/${TEST_ENDPOINT}`, { testWithLatestData });
  }

  async updateDataProductDestinations(dataProductId, payload) {
    const { data } = await this.client.put(`${dataProductId}/${DATA_PRODUCT_DESTINATIONS_ENDPOINT}`, payload);

    return data;
  }

  updateDataProduct(dataProductId, payload) {
    return this.client.put(`/${dataProductId}`, payload);
  }

  async updateDataProductSubscription(dataProductId, subscriptionId, payload) {
    const { data } = await this.client.put(`${dataProductId}/subscriptions/${subscriptionId}`, payload);

    return data;
  }

  async deleteDataProduct(dataProductId) {
    await this.client.delete(`/${dataProductId}`);
  }
}
