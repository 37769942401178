import ApiBase from './ApiBase';

export const DEADLINES_ENDPOINT = 'deadlines';
export const NAME_ENDPOINT = 'name';
export const OVERRIDE_ENDPOINT = 'override';
export const STATUS_ENDPOINT = 'status';
export const WORKFLOW_CONFIG_ENDPOINT = 'workflow-config';
export const WORKFLOWS_ENDPOINT = 'workflows';

export default class InferredDatasetService extends ApiBase {
  async applyOverrides(inferredDatasetId, payload) {
    const { data } = await this.client.put(`/${inferredDatasetId}/${OVERRIDE_ENDPOINT}`, payload);

    return data;
  }

  async createWorkflow(inferredDatasetId) {
    const { data } = await this.client.post(`/${inferredDatasetId}/${WORKFLOWS_ENDPOINT}`);

    return data;
  }

  async fetchDeadlines(inferredDatasetId) {
    const { data } = await this.client.get(`/${inferredDatasetId}/${DEADLINES_ENDPOINT}`);

    return data;
  }

  async fetchOne(inferredDatasetId) {
    const { data } = await this.client.get(`/${inferredDatasetId}`);

    return data;
  }

  async fetchStatus(inferredDatasetId) {
    const { data } = await this.client.get(`/${inferredDatasetId}/${STATUS_ENDPOINT}`);

    return data;
  }

  async fetchWorkflowConfig(inferredDatasetId) {
    const { data } = await this.client.get(`/${inferredDatasetId}/${WORKFLOW_CONFIG_ENDPOINT}`);

    return data;
  }

  async fetchWorkflows(inferredDatasetId) {
    const { data } = await this.client.get(`/${inferredDatasetId}/${WORKFLOWS_ENDPOINT}`);

    return data;
  }

  async startOverride(inferredDatasetId) {
    const { data } = await this.client.post(`/${inferredDatasetId}/${OVERRIDE_ENDPOINT}`);

    return data;
  }

  async updateDeadlines(inferredDatasetId, deadlines) {
    const { data } = await this.client.put(`/${inferredDatasetId}/${DEADLINES_ENDPOINT}`, { deadlines });

    return data;
  }

  async updateName(inferredDatasetId, name) {
    const { data } = await this.client.put(`/${inferredDatasetId}/${NAME_ENDPOINT}`, { name });

    return data;
  }
}
