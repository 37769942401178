export const CATALOG_DATASETS = [
  {
    afInstance: 'Pr2jTKBeHL6j',
    afVar: '868',
    approved: false,
    attachments: [],
    bucketLocation: 'US',
    bucketName: 'com-crux-533512df6128fad25447f428bd9275af',
    createdAt: '2023-07-31T16:12:40.127+00:00',
    cronSchedule: '@once',
    deletedOn: '2023-11-02T20:03:31.220+00:00',
    description: '',
    id: 'Ds60gdLM',
    legacyId: 'AQI60gdLMG4-ccCGHa9RioQMvQ',
    modifiedAt: '2023-07-31T16:13:54.626+00:00',
    name: 'crux_testing_llc_integrate_fastertest_fasttest_v7',
    ownerIdentityId: 'OrEC0NbO',
    publicDs: false,
    status: 'In Progress',
    tags: [],
    timezone: 'UTC',
    workflowId: 'crux_testing_llc_integrate_fastertest_fasttest_v7',
  },
  {
    afInstance: 'PrYRDOGhTYIv',
    afVar: '622',
    approved: false,
    attachments: [],
    bucketLocation: 'US',
    createdAt: '2023-05-05T15:49:29.575+00:00',
    cronSchedule: '@once',
    description: '',
    id: 'DskB-vrw',
    legacyId: 'AQJkB-vrwONeIuDraKRvimvXTA',
    modifiedAt: '2023-05-05T15:49:29.575+00:00',
    name: 'crux_testing_llc_dev_lw_test_1',
    publicDs: false,
    status: 'In Progress',
    tags: [],
  },
  {
    afInstance: 'PrdrU9jecY83',
    afVar: '2774',
    approved: false,
    attachments: [],
    bucketLocation: 'US',
    bucketName: 'com-crux-07bca5499c5c73d88fa7bde55bd5c602',
    createdAt: '2023-11-09T03:26:45.224+00:00',
    cronSchedule: '@once',
    description: '',
    id: 'Ds62TnAS',
    legacyId: 'AQK62TnAS91s-igjSqvvtb4IRQ',
    modifiedAt: '2023-11-09T03:26:45.224+00:00',
    name: 'crux_testing_llc_azguardrailpatterntypeinteg5067_hgajra_rise_anz_20221125_20221128',
    ownerIdentityId: 'OrEC0NbO',
    publicDs: false,
    status: 'In Progress',
    tags: [],
    timezone: 'UTC',
  },
];

export const [CATALOG_DATASET] = CATALOG_DATASETS;

export const DATASET_EVENTS = [
  {
    id: '6f232aeb-166b-41d4-b9df-77366bca5514',
    message: 'Dataset deployment success',
    status: 'Success',
    timestamp: '2025-01-14T18:35:21.053417Z',
  },
  {
    id: '4c126e9b-a846-44f9-8aed-d381f916f475',
    message: 'Start dataset deployment',
    status: 'Apply',
    timestamp: '2025-01-14T18:31:47.82783Z',
  },
  {
    id: '54db0fc8-2562-40c7-a46e-a220e1cc3a7a',
    message: '403 User not authorized to perform this action.',
    status: 'Failure',
    timestamp: '2025-01-14T18:31:22.85697176Z',
  },
  {
    id: '4bbaf916-f1f1-4482-8164-5dd0b648387a',
    message: 'Start semantic validation',
    status: 'Apply',
    timestamp: '2025-01-14T18:31:22.652139Z',
  },
];

export const DATASET_SELECT_OPTIONS = [
  {
    label: 'crux_testing_llc_integrate_fastertest_fasttest_v7',
    value: 'Ds60gdLM',
  },
  {
    label: 'crux_testing_llc_dev_lw_test_1',
    value: 'DskB-vrw',
  },
  {
    label: 'crux_testing_llc_azguardrailpatterntypeinteg5067_hgajra_rise_anz_20221125_20221128',
    value: 'Ds62TnAS',
  },
];

export const DATASET_WORKFLOW_CONFIG = {
  dataset_id: 'DsSVdBaU',
  global: {
    extract: {
      action_class: 'pipeline.crux_pdk.actions.extract.extractor.Extractor',
      conf: {
        file_patterns: [],
        unzip_patterns: [],
      },
      connection: {
        type: 'sftp',
      },
      fetch_method: 'fetch_dir',
      remote_path: '/',
    },
    process: {
      action_class: 'pipeline.crux_pdk.actions.process.java_processor.JavaProcessor',
      delimiter: ',',
      file_has_header: true,
      quotechar: '"',
    },
  },
  id: 'crux_testing_llc_ssy_240110_2_integrate_single_fastertest_fasttest',
  run_uber_step: true,
};

export const DATASET_YAML_CONFIG = `dataset_id: DsSVdBaU
global:
  extract:
    action_class: pipeline.crux_pdk.actions.extract.extractor.Extractor
    conf:
      file_patterns: []
      unzip_patterns: []
    connection:
      type: sftp
    fetch_method: fetch_dir
    remote_path: /
  process:
    action_class: pipeline.crux_pdk.actions.process.java_processor.JavaProcessor
    delimiter: ","
    file_has_header: true
    quotechar: '"'
id: crux_testing_llc_ssy_240110_2_integrate_single_fastertest_fasttest
run_uber_step: true`;
