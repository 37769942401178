import { DISMISSED_DEPLOY_ALERTS_KEY } from '~/support/constants';
import {
  AdaptersService,
  CdusService,
  DataProductsService,
  DestinationsService,
  ResourcesService,
  SourcesService,
} from '~/support/services';

import {
  DELETE_WORKFLOW_FRAME,
  MERGE_INFERRED_DATASET_INFO,
  REMEMBER_PROFILING_NOTIFICATION_MODAL,
  RESET_DATA_PRODUCT,
  RESET_DATA_PRODUCTS,
  SET_CONNECTION,
  SET_CONNECTIONS,
  SET_DATA_PRODUCT,
  SET_DATA_PRODUCT_DATASETS,
  SET_DATA_PRODUCT_DESTINATIONS,
  SET_DATA_PRODUCT_HISTORY,
  SET_DATA_PRODUCT_ONBOARDING_STATUS,
  SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE,
  SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING,
  SET_DESTINATIONS,
  SET_DISMISSED_DEPLOY_ALERTS,
  SET_RESOURCES,
  SET_WORKFLOW_FRAME_NAME,
  UPDATE_CONNECTION,
  UPDATE_DATASTORES_MAP,
} from './mutation-types';

export default {
  activateDataProduct(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return service.activateDataProduct(dataProductId);
  },

  async activateProfilingNotification({ commit, dispatch }, { dataProductId }) {
    try {
      commit(SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING, true);

      const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

      await service.activateProfilingNotification({ dataProductId });

      await dispatch('setProfilingNotificationActive', true);
    } finally {
      commit(SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING, false);
    }
  },

  async bulkCreate(_, { dataProducts }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.bulkCreate(dataProducts);

    return data;
  },

  async canActivateDataProduct(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.canActivateDataProduct(dataProductId);
  },

  cancelProfiling(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return service.cancelProfiling(dataProductId);
  },

  async cancelProfilingNotification({ commit, dispatch }, { dataProductId }) {
    try {
      commit(SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING, true);

      const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

      await service.cancelProfilingNotification({ dataProductId });

      await dispatch('setProfilingNotificationActive', false);
    } finally {
      commit(SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING, false);
    }
  },

  async createConnection({ commit, dispatch }, payload) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);
    const data = await service.createSource(payload);

    commit(SET_CONNECTION, data);
    dispatch('fetchDataProduct', payload.productId);

    return data;
  },

  async createCustomConnector(_, payload) {
    const service = new AdaptersService(useRuntimeConfig().public.adaptersBaseUrl);
    const data = await service.createCustomConnector(payload);

    return data;
  },

  async createDataProduct(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.createDataProduct(payload);

    return data;
  },

  async createDestinationConnection({ dispatch }, payload) {
    const service = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);
    const data = await service.createDestination(payload);

    if (payload.productId) {
      dispatch('fetchDataProduct', payload.productId);
      dispatch('fetchDataProductDestinations', payload.productId);
    }

    return data;
  },

  async createInferredDatasets(_, { dataProductId, patterns }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.createInferredDatasets(dataProductId, patterns);

    return data;
  },

  async createWorkflows(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.createWorkflows(payload);

    return data;
  },

  deactivateDataProduct(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return service.deactivateDataProduct(dataProductId);
  },

  async deleteDataProduct(_, { dataProductId }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    await service.deleteDataProduct(dataProductId);
  },

  async deleteDataProductSubscription(_, params) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    await service.deleteDataProductSubscription(params);
  },

  async deleteIntegrationEntities(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.deleteIntegrationEntities(dataProductId);
  },

  deleteWorkflowFrame({ commit }, payload) {
    commit(DELETE_WORKFLOW_FRAME, payload);
  },

  excludeFrames(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return service.excludeFrames(payload);
  },

  async fetchAllConfigs(_, id) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchAllConfigs(id);

    return data;
  },

  async fetchCdu(_, id) {
    const service = new CdusService(useRuntimeConfig().public.cdusBaseUrl);
    const data = await service.fetchCdu(id);

    return data;
  },

  async fetchConnectionByProductId({ commit, dispatch }, id) {
    dispatch('loading/startLoading', 'fetchConnectionByProductId', { root: true });
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchConnectionByProductId(id);

    commit(SET_CONNECTION, data);
    dispatch('loading/stopLoading', 'fetchConnectionByProductId', { root: true });

    return data;
  },

  async fetchCustomConnectors() {
    const service = new AdaptersService(useRuntimeConfig().public.adaptersBaseUrl);
    const data = await service.fetchCustomConnectors();

    return data;
  },

  async fetchCustomNormalizers(_) {
    const service = new AdaptersService(useRuntimeConfig().public.adaptersBaseUrl);
    const data = await service.fetchCustomNormalizers();

    return data;
  },

  async fetchDataProduct({ commit, dispatch, state }, id) {
    if (state.lastDataProductId !== id) {
      await dispatch('profiler/resetData', null, { root: true });
    }

    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDataProduct(id);

    commit(SET_DATA_PRODUCT, data);
    commit(SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE, data.profilingNotificationActive);

    return data;
  },

  async fetchDataProductDeploymentChecks(_, id) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const { data } = await service.fetchDataProductDeploymentChecks(id);

    return data;
  },

  async fetchDataProductDestinationMeta(_, id) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDataProductDestinationMeta(id);

    return data;
  },

  async fetchDataProductDestinations({ commit, dispatch }, id) {
    dispatch('loading/startLoading', 'fetchDataProductDestinations', { root: true });
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDataProductDestinations(id);
    commit(SET_DATA_PRODUCT_DESTINATIONS, data);
    dispatch('loading/stopLoading', 'fetchDataProductDestinations', { root: true });

    return data;
  },

  async fetchDataProductFamiliesByOrg(_, legacyId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDataProductFamiliesByOrg(legacyId);

    return data;
  },

  async fetchDataProductHistory({ commit }, id) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDataProductHistory(id);

    commit(SET_DATA_PRODUCT_HISTORY, data);

    return data;
  },

  async fetchDataProductStatus(_, id) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const { data } = await service.fetchDataProductStatus(id);

    return data;
  },

  async fetchDataProductSubscribers(_, params) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    const data = await service.fetchDataProductSubscribers(params);

    return data;
  },

  async fetchDataProductSubscription(_, params) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    const data = await service.fetchDataProductSubscription(params);

    return data;
  },

  async fetchDataProducts(_, params = {}) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    const data = await service.fetchDataProducts(params);

    return data;
  },

  async fetchDatasets({ commit }, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDatasets(dataProductId);

    commit(SET_DATA_PRODUCT_DATASETS, data);

    return data;
  },

  async fetchDestinations({ commit }, params = {}) {
    const service = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);
    const data = await service.fetchAll(params);

    commit(SET_DESTINATIONS, data);

    return data;
  },

  async fetchEnums(_, name) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchEnums(name);

    return data;
  },

  async fetchEnumsList(_) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const enums = await service.fetchEnumsList();

    return enums.data;
  },

  async fetchFilePatterns({ commit }, datastoreId) {
    const service = new DataProductsService(useRuntimeConfig().public.datastoresBaseUrl);

    return await service.fetchFilePatterns(datastoreId);
  },

  async fetchResources({ commit }) {
    const service = new ResourcesService(useRuntimeConfig().public.resourcesBaseURL);
    const { data } = await service.fetchResources();

    commit(SET_RESOURCES, data);

    return data;
  },

  async fetchSource({ commit }, connectionId) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);
    const data = await service.fetchOne(connectionId);

    commit(UPDATE_CONNECTION, data);

    return data;
  },

  async fetchSourceStatus(_, connectionId) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);
    const data = await service.fetchStatus(connectionId);

    return data;
  },

  async fetchSources({ commit }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);
    const data = await service.fetchAll();

    commit(SET_CONNECTIONS, data);

    return data;
  },

  getDismissedDeployAlerts({ commit }) {
    const dismissedDeployAlerts = localStorage.getItem(DISMISSED_DEPLOY_ALERTS_KEY);
    const parsedDismissedDeployAlerts = dismissedDeployAlerts ? JSON.parse(dismissedDeployAlerts) : [];

    commit(SET_DISMISSED_DEPLOY_ALERTS, parsedDismissedDeployAlerts);
  },

  async importDataProduct(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.importDataProduct(payload);

    return data;
  },

  mergeInferredDatasetInfo({ commit }, payload) {
    commit(MERGE_INFERRED_DATASET_INFO, payload);
  },

  async patchDataProduct({ dispatch }, { dataProductId, payload }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    await service.patchDataProduct(dataProductId, payload);

    await dispatch('fetchDataProduct', dataProductId);
  },

  async pauseDataProduct(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.pauseDataProduct(dataProductId);
  },

  async profile(_, { dataProductId, frameIds, lastDeliveryOnly }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.profile({ dataProductId, frameIds, lastDeliveryOnly });
  },

  redeployDataProduct(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return service.redeployDataProduct(dataProductId);
  },

  rememberProfilingNotificationModal({ commit }) {
    commit(REMEMBER_PROFILING_NOTIFICATION_MODAL);
  },

  async reprofile(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.reprofile(payload);
  },

  resetDataProduct({ commit }) {
    commit(RESET_DATA_PRODUCT);
  },

  resetDataProducts({ commit }) {
    commit(RESET_DATA_PRODUCTS);
  },

  async resumeDataProduct(_, dataProductId) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.resumeDataProduct(dataProductId);
  },

  setDismissedDeployAlerts({ commit }, dismissedDeployAlerts) {
    localStorage.setItem(DISMISSED_DEPLOY_ALERTS_KEY, JSON.stringify(dismissedDeployAlerts));
    commit(SET_DISMISSED_DEPLOY_ALERTS, dismissedDeployAlerts);
  },

  setProfilingNotificationActive({ commit }, active) {
    commit(SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE, active);
  },

  setWorkflowFrameName({ commit }, payload) {
    commit(SET_WORKFLOW_FRAME_NAME, payload);
  },

  async skipProfiling(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return await service.skipProfiling(payload);
  },

  testDataProduct(_, payload) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    return service.testDataProduct(payload);
  },

  async updateDataProduct({ dispatch }, { dataProductId, fetchDataProduct = true, payload }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    await service.updateDataProduct(dataProductId, payload);

    if (fetchDataProduct) {
      await dispatch('fetchDataProduct', dataProductId);
    }
  },

  async updateDataProductOnboardingStatus({ commit, dispatch, state }, id) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.fetchDataProduct(id);

    commit(SET_DATA_PRODUCT_ONBOARDING_STATUS, data.onboardingStatus);
  },

  async updateDataProductSubscription(_, { dataProductId, subscriptionId, payload }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);

    const data = await service.updateDataProductSubscription(dataProductId, subscriptionId, payload);

    return data;
  },

  updateDatastoresMap({ commit }, payload) {
    commit(UPDATE_DATASTORES_MAP, payload);
  },

  async updateDestinationAssociations({ commit, dispatch }, { dataProductId, payload }) {
    const service = new DataProductsService(useRuntimeConfig().public.dataProductsBaseUrl);
    const data = await service.updateDataProductDestinations(dataProductId, payload);

    dispatch('fetchDataProductDestinations', dataProductId);
    dispatch('fetchDataProduct', dataProductId);

    return data;
  },
};
